import React, { useState, useEffect } from "react";
import "./Belowheader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export const Belowheader = () => {
  const [zeltondata, setData] = useState();

  const fetchJson = () => {
    fetch("data/zelton.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    fetchJson();
  }, []);

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bhInfo bh-address-margin-top">
            {zeltondata?.contactusPage?.AddressContent}
          </div>
          <div className="col-lg-12 bh-contact bh-info-margin-top bh-contact-font-size">
            <span id="emailId">
              {" "}
              <FontAwesomeIcon icon={faEnvelope} />
              {zeltondata?.webdata?.emailid}
            </span>
            <span id="telId">
              {" "}
              <FontAwesomeIcon icon={faPhone} />
              {zeltondata?.webdata?.mobilenumber}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
